import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { fetchNestedDashboards } from 'actions/dashboardActions';
import cn from 'classnames';
import { AuthModal } from 'components/AuthModal';
import { Chat } from 'components/Chat';
import { ChatInput } from 'components/ChatInput';
import { Message } from 'entities/Message.entity';
import { queryKeys } from 'enums';
import { Routes } from 'enums/Routes';
import { useAuthContext } from 'hooks';
import {
  useCreateChat,
  useCreateMessage,
  useMessages,
  useUserCoins,
  useUserInfo
} from 'hooks/api';
import { useChatDetails } from 'hooks/api/useChatDetails';
import { useFetchDashboardData } from 'hooks/api/useFetchDashboardData';
import { useRegenerateChatMessage } from 'hooks/api/useRegenerateChatMessage';
import { useGlobalShortcutsContext } from 'hooks/useGlobalShortcutsContext';
import { useIndustryConversation } from 'hooks/useIndustryConversation';
import { queryClient } from 'index';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import { SessionStorage } from 'services/SessionStorage';
import { SAVED_USER_QUERY } from 'utils/constants';
import { handleAnimationSwitch } from 'utils/helpers/animationHelper';

import { MoreDataModal } from './MoreDataModal';
import { Suggestions } from './Suggestions';

import styles from './styles.module.scss';

export const Conversation: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setChatId } = useGlobalShortcutsContext();
  const { isAuthenticated } = useAuthContext();
  const navigate = useNavigate();
  const previousChatId = useRef<string | undefined>();
  const { chatId, industry } = useParams();
  const { data: coinsNumber } = useUserCoins();
  const { data: userInfo } = useUserInfo();
  const isPublicChat = !location.pathname.includes('/chats/');
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutate: fetchDashboardData } = useFetchDashboardData();
  const { data: chatDetails } = useChatDetails(chatId);

  const subject = chatDetails?.subject;

  const disableNewQuestions =
    !!userInfo?.deactivatedAt && location.pathname === Routes.NewChat;

  const abortController = useRef<AbortController | null>(null);
  const [isChatHidden, setIsChatHidden] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoadingAnimation, setIsLoadingAnimation] = useState(false);
  const [extendedMessages, setExtendedMessages] = useState<Message[] | null>(
    null
  );
  const [isAuthModalOpen, setAuthModalOpen] = useState<boolean>(false);
  const [isMoreDataModalOpen, setMoreDataModalOpen] = useState<boolean>(false);

  const handleCloseAuthModal = () => {
    setAuthModalOpen(false);
  };

  const handleOpenAuthModal = () => {
    setAuthModalOpen(true);
  };

  const handleCloseMoreMoreDataModal = () => {
    setMoreDataModalOpen(false);
  };

  const handleOpenMoreMoreDataModal = () => {
    if (!isAuthenticated) {
      SessionStorage.setItem(SAVED_USER_QUERY, searchValue);
      handleOpenAuthModal();
      return;
    }

    setMoreDataModalOpen(true);
  };

  const interruptMessage = () => {
    abortController.current?.abort();
  };

  const {
    mutate: sendMessageMutation,
    isPending,
    isMessageSending
  } = useCreateMessage({
    abortController,
    setSearchValue,
    setIsLoadingAnimation
  });
  const {
    mutate: regenerateMessageMutation,
    isPending: isRegenerateMessageSending,
    isRegenerateMessageRequestPending
  } = useRegenerateChatMessage({
    abortController
  });
  const { mutate: createChatMutation } = useCreateChat(
    chatId,
    sendMessageMutation
  );
  const {
    data: messages,
    isLoading: isMessagesLoading,
    isFetchedAfterMount
  } = useMessages(chatId, isPending);

  useIndustryConversation({
    createChat: createChatMutation,
    setMessages: setExtendedMessages,
    isMessagesFetched: isFetchedAfterMount
  });

  useEffect(() => {
    const questionFromQuery = searchParams.get('question');
    const questionFromStorage =
      SessionStorage.getItem<string>(SAVED_USER_QUERY);

    setSearchValue(questionFromQuery || questionFromStorage || '');

    if (!isPublicChat) {
      if (questionFromStorage) {
        SessionStorage.removeItem(SAVED_USER_QUERY);
      }

      if (questionFromQuery) {
        searchParams.delete('question');

        setSearchParams(searchParams);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chatId) {
      queryClient.invalidateQueries({
        queryKey: queryKeys.chatMessages(chatId || '')
      });

      setChatId(chatId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);

  useEffect(() => {
    if (
      !chatId ||
      (previousChatId?.current != null &&
        chatId &&
        chatId !== previousChatId?.current)
    ) {
      interruptMessage();
    }

    if (chatId) {
      previousChatId.current = chatId;
    }

    return () => {
      if (!chatId || chatId === 'new') {
        previousChatId.current = undefined;
      }
    };
  }, [chatId]);

  useEffect(() => {
    if (messages?.length && (isFetchedAfterMount || chatId)) {
      setExtendedMessages(messages);
    }
  }, [messages, chatId, isFetchedAfterMount]);

  useEffect(() => {
    if (chatId || industry || subject) {
      handleAnimationSwitch(true);
    } else {
      handleAnimationSwitch(false);
    }
  }, [chatId, industry, subject]);

  const askQuestion = (message: string) => {
    if (!chatId) {
      createChatMutation({ message });
    } else {
      sendMessageMutation({ message, currentChatId: chatId });
    }
  };

  const regenerateMessage = (messageId: string) => {
    if (chatId) {
      regenerateMessageMutation({ messageId, currentChatId: chatId });
    }
  };

  const isLoading =
    isPending || isLoadingAnimation || isRegenerateMessageRequestPending;

  const sendMixpanelQuestionsAskedEvent = () => {
    mixpanel?.track(MixpanelEvents.QuestionAsked, {
      'Question amount': coinsNumber?.requestsCount,
      'User plan': userInfo?.subscriptionPlan
    });
  };

  const handleSubmit = () => {
    if (!isAuthenticated) {
      SessionStorage.setItem(SAVED_USER_QUERY, searchValue);
      handleOpenAuthModal();
      return;
    }

    if (searchValue && !isLoading) {
      mixpanel?.track(MixpanelEvents.QuestionSubmit, {
        Text: searchValue,
        'Chat ID': chatId
      });

      sendMixpanelQuestionsAskedEvent();
      askQuestion(searchValue);
    }
  };

  const handleSuggestionClick = (text: string) => {
    if (!isAuthenticated) {
      SessionStorage.setItem(SAVED_USER_QUERY, text);
      handleOpenAuthModal();
      return;
    }

    sendMixpanelQuestionsAskedEvent();
    setSearchValue(text);
    askQuestion(text);
  };

  const onSuccess = (dashboardId: string) => {
    queryClient.invalidateQueries({
      queryKey: queryKeys.filteredChats({ includeArchived: false })
    });

    queryClient.invalidateQueries({
      queryKey: queryKeys.filteredChats({ includeArchived: true })
    });

    if (dashboardId) {
      queryClient.invalidateQueries({
        queryKey: queryKeys.nestedDashboards(dashboardId)
      });
      queryClient.fetchQuery({
        queryKey: queryKeys.nestedDashboards(dashboardId),
        queryFn: () => fetchNestedDashboards(dashboardId)
      });
    }
  };

  const handleDashboardClick = (dashboardId: string) => {
    fetchDashboardData({
      dashboardId,
      onSuccess: () => onSuccess(dashboardId)
    });

    mixpanel?.track(MixpanelEvents.DashboardGenerated, {
      'Dashboard ID': dashboardId,
      'Chat ID': chatId,
      'User plan': userInfo?.subscriptionPlan,
      'Questions asked': coinsNumber?.requestsCount
    });

    handleAnimationSwitch(false);
    setIsLoadingAnimation(true);
    setIsChatHidden(true);
    setSearchValue(t('Page.Chat.LoadingDashboard'));

    setTimeout(() => {
      setSearchValue('');
      setIsLoadingAnimation(false);

      const link = Routes.Dashboard.replace(':dashboardId', dashboardId || '');

      navigate(link);
    }, 2500);
  };

  const renderChat = () => {
    if (
      !industry &&
      !subject &&
      (!messages?.length || !extendedMessages?.length)
    )
      return null;

    const isRateButtonHidden = !!userInfo?.deactivatedAt || !!industry;

    return (
      <div
        className={cn(styles.chat, {
          [styles.hidden]: isChatHidden
        })}
      >
        <Chat
          isLoading={isLoading}
          isMessageSending={isMessageSending}
          interruptMessage={interruptMessage}
          regenerateChatMessage={regenerateMessage}
          isRateButtonHidden={isRateButtonHidden}
          messages={extendedMessages as Message[]}
          onShowDashboardClick={handleDashboardClick}
          onSuggestionClick={handleSuggestionClick}
          onRequestMoreDataClick={handleOpenMoreMoreDataModal}
          isRegenerateMessageSending={isRegenerateMessageSending}
        />
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {!isMessagesLoading && (
        <div className={styles.content}>
          <div className={styles.conversation}>
            {!messages?.length && !industry && !subject && (
              <h1>{t('Page.Chat.Title')}</h1>
            )}

            <Suggestions
              disabled={disableNewQuestions}
              isVisible={
                !industry && !subject && !messages?.length && !isLoading
              }
              onSuggestionClick={handleSuggestionClick}
            />

            {renderChat()}

            <div className={styles.input}>
              <ChatInput
                value={searchValue}
                isLoading={isLoading}
                setValue={setSearchValue}
                onSubmit={handleSubmit}
                disabled={disableNewQuestions}
              />
            </div>
          </div>
        </div>
      )}

      <MoreDataModal
        isOpen={isMoreDataModalOpen}
        onClose={handleCloseMoreMoreDataModal}
      />
      <AuthModal isOpen={isAuthModalOpen} onClose={handleCloseAuthModal} />
    </div>
  );
};
