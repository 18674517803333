import { UserCoin } from 'entities/UserCoin.entity';

import { ApiBase } from '../ApiBase';

class CommonApi extends ApiBase {
  fetchCoins() {
    // TODO: Change to Coin on BE
    return this.client.get<UserCoin>('/credits');
  }
}

const instance = new CommonApi('');

export { instance as CommonApi };
