import { ChartUnitTypes } from 'enums';
import { BigNumberUnits } from 'enums/BigNumberUnits.enum';
import {
  custom,
  deserialize,
  list,
  object,
  primitive,
  serializable
} from 'serializr';

import { DashboardCompany } from './DashboardCompany.entity';
import { DashboardParamsData } from './DashboardParamsData.entity';
import { Message } from './Message.entity';

export class DashboardParams {
  @serializable(list(object(Message)))
  history: Message[] | null = null;

  @serializable(list(object(DashboardCompany)))
  companies: DashboardCompany[] | null = null;

  @serializable
  text: string | null = '';

  @serializable
  title: string | null = '';

  @serializable
  name?: string | null = '';

  @serializable
  materials?: string | null = '';

  @serializable
  technology?: string | null = '';

  @serializable(
    custom(
      (sourcePropertyValue: string) => {
        return sourcePropertyValue;
      },
      (jsonValue: (number[] | null | number)[] | null) => {
        return jsonValue || null;
      }
    )
  )
  values?: (number[] | null | number)[] | null = null;

  @serializable(
    custom(
      (sourcePropertyValue: string) => {
        return sourcePropertyValue;
      },
      (jsonValue: (number[] | null | number)[] | null) => {
        return jsonValue || null;
      }
    )
  )
  absolute_values?: (number[] | null | number)[] | null = null;

  @serializable
  value?: number = 0;

  @serializable
  max_companies?: number = 0;

  @serializable
  displayed_amount?: number = 0;

  @serializable
  max_amount?: number = 0;

  @serializable
  bins?: number = 0;

  @serializable
  unit: BigNumberUnits | null = null;

  @serializable
  type: ChartUnitTypes | null = null;

  @serializable(list(primitive()))
  labels?: string[] | null = null;

  @serializable(list(object(DashboardParamsData)))
  data?: DashboardParamsData[] | null = null;

  static deserialize(json: Object | string): DashboardParams {
    return deserialize(DashboardParams, json);
  }
}
