import { FC, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Routes } from 'enums';
import { useAuthContext } from 'hooks/useAuthContext';
import { ProfileTabs } from 'views/Profile/Profile';

import styles from './styles.module.scss';

interface Props {
  onLoad(value: boolean): void;
}

const ProfileRoutes = Object.values(ProfileTabs).map((tab) =>
  Routes.Profile.replace(':tab?', tab)
);

const DashboardRoute = Routes.Dashboard.replace(':dashboardId', '');

const animationDisabledRoutes = [
  ...ProfileRoutes,
  DashboardRoute,
  Routes.Auth,
  Routes.Login,
  Routes.Registration,
  Routes.ForgotPassword,
  Routes.RestorePassword,
  Routes.ConfirmRestorePassword,
  Routes.NewDevelopments,
  Routes.Checkout,
  Routes.Dashboard,
  Routes.OneTimePaymentCheckout
];

export const CloudExperience: FC<Props> = ({ onLoad }) => {
  const cloudExperienceRef = useRef<HTMLElement>(null);
  const location = useLocation();

  const { isAuthenticated } = useAuthContext();
  const [isLoaded, setIsLoaded] = useState(false);

  const isAnimationDisabled = animationDisabledRoutes.some((route) =>
    location.pathname.includes(route)
  );

  useEffect(() => {
    const isScriptExists = document.getElementById('cloud-experience-script');

    if (!isScriptExists) {
      const script = document.createElement('script');
      script.onload = () => setIsLoaded(true);
      script.type = 'module';
      script.id = 'cloud-experience-script';
      script.crossOrigin = 'true';
      script.src = '/cloud_experience/main.js';

      document.body.appendChild(script);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isLoaded) {
      onLoad(isLoaded);

      // @ts-expect-error error type
      window.unloadHandlers.unshift(() => {
        if (cloudExperienceRef?.current) {
          cloudExperienceRef.current.remove();
        }
      });
    }
  }, [isLoaded, onLoad]);

  return isLoaded ? (
    <cloud-experience
      ref={cloudExperienceRef}
      class={cn(styles.animation, isAnimationDisabled && styles.hidden)}
    />
  ) : null;
};
