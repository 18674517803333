import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Footer } from 'components';
import { useUpdateUserInfo } from 'hooks/api/useUpdateUserInfo';
import { useUserInfo } from 'hooks/api/useUserInfo';
import { LocalStorage } from 'services/LocalStorage';
import { APP_UI_LANGUAGE, DEFAULT_PREFERRED_LANGUAGE } from 'utils/constants';

import {
  NewDevelopmentsItem,
  type NewDevelopmentsItemData
} from './NewDevelopmentsItem';

import styles from './styles.module.scss';

export const NewDevelopments: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: userInfo } = useUserInfo();
  const { mutate: updateUserInfo } = useUpdateUserInfo(false);
  const [notesData, setNotesData] = useState<NewDevelopmentsItemData[] | null>(
    null
  );

  useEffect(() => {
    if (userInfo) {
      const [language] = (
        userInfo?.preferredLanguage ||
        (LocalStorage.getItem(APP_UI_LANGUAGE) as string) ||
        DEFAULT_PREFERRED_LANGUAGE
      ).split('-');

      switch (language) {
        case 'de':
          import('./data/de.json').then((response) =>
            setNotesData(response.default)
          );
          break;
        case 'en':
        default:
          import('./data/en.json').then((response) =>
            setNotesData(response.default)
          );
          break;
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo?.showReleaseNotes) {
      updateUserInfo({ showReleaseNotes: false });
    }
  }, [userInfo?.showReleaseNotes, updateUserInfo]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <Button
            color="primary"
            size="small"
            variant="contained"
            className={styles.back}
            onClick={() => navigate(-1)}
          >
            {t('Common.Back')}
          </Button>
          <h1 className={styles.title}>{t('Page.NewDevelopments.Title')}</h1>

          {(notesData as NewDevelopmentsItemData[])?.map((props, index) => (
            <NewDevelopmentsItem
              key={props.title}
              {...props}
              isLastItem={notesData ? index === notesData.length - 1 : false}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};
