import { FC } from 'react';
import { Navigate, Outlet } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Routes } from 'enums';
import { useUserInfo } from 'hooks/api/useUserInfo';
import { useAuthContext } from 'hooks/useAuthContext';

export interface Props {
  redirectPath?: string;
}

export const WithVerifiedUserRoute: FC<Props> = ({
  redirectPath = Routes.VerifyUser
}) => {
  const [searchParams] = useSearchParams();
  const { logOut } = useAuthContext();
  const response = useUserInfo();
  const { isError, isLoading, data } = response;

  if (isLoading) return null;

  if (isError) logOut();

  const shouldRedirect = !data?.lastName;

  let path = redirectPath;

  if (searchParams.size) {
    path = `${path}?${searchParams}`;
  }

  return shouldRedirect ? <Navigate replace to={path} /> : <Outlet />;
};
