import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { Routes } from 'enums/Routes';
import { queryClient } from 'index';
import { clearStorage } from 'utils/helpers/authHelpers';

export const responseSuccessInterceptor = (
  response: AxiosResponse
): AxiosResponse => {
  return response;
};

export const responseErrorInterceptor =
  (client: AxiosInstance) =>
  async (
    error: AxiosError
  ): Promise<AxiosResponse<AxiosInstance | AxiosError>> => {
    if (error?.response?.status === 401) {
      const authRoutes = [
        Routes.Auth,
        Routes.Registration,
        Routes.Login,
        Routes.Confirm,
        Routes.UpdateEmailConfirm,
        Routes.RestorePassword,
        Routes.Unsubscribe
      ];

      const isIndustryChatRoute =
        window.location.pathname.includes('/industry/');

      if (
        authRoutes.includes(window.location.pathname as Routes) ||
        isIndustryChatRoute
      ) {
        return Promise.reject(error);
      }

      try {
        await axios.post('/auth/refresh-token', undefined, {
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true
        });
        return client(error.response!.config);
      } catch (_error) {
        await axios.post('/auth/logout', undefined, {
          baseURL: process.env.REACT_APP_BASE_URL
        });
        clearStorage();
        queryClient.removeQueries();

        window.location.href = Routes.Auth;
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  };

export abstract class ApiBase {
  protected readonly client: AxiosInstance;

  constructor(prefix = '') {
    const client = axios.create({
      withCredentials: true,
      baseURL: process.env.REACT_APP_BASE_URL + prefix,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Timezone-Offset': new Date().getTimezoneOffset()
      }
    });

    client.interceptors.response.use(
      responseSuccessInterceptor,
      responseErrorInterceptor(client)
    );

    this.client = client;
  }
}
