const generateRandomName = () => {
  const names = [
    'Lorem Ipsum A',
    'Company simply text',
    'Cof the printing',
    'And typesetting D',
    'Text ever',
    'Company simply text',
    'Cof the printing',
    'What Specimen book',
    'Lorem Ipsum A',
    'Company simply text',
    'Cof the printing',
    'And typesetting D',
    'B Lorem Ipsum',
    'Company simply text',
    'Cof the printing',
    'Ipsum',
    'A Lorem Ipsum A',
    'Company simply text',
    'Cof the printing',
    'And typesetting D'
  ];
  return names[Math.floor(Math.random() * names.length)];
};

const generateRandomMeterials = (count = 1) => {
  const materials = [
    'Material Ipsum',
    'Company material text',
    'Cof the printing',
    'And typesetting',
    'Text material',
    'Materiall',
    'Cof material printing',
    'What Specimen book',
    'Lorem Ipsum A',
    'Company material text',
    'Cof the printing',
    'And material D',
    'B Lorem Ipsum',
    'Company simply text',
    'Cof material printing',
    'Ipsum',
    'A Lorem Ipsum A',
    'Company material text',
    'Cof the printing',
    'And material D'
  ];
  const randomNames = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < count; i++) {
    const randomIndex = Math.floor(Math.random() * materials.length);
    randomNames.push(materials[randomIndex]);
  }
  return randomNames;
};

const generateRandomLocation = () => {
  const locations = [
    'Counpsum',
    'Stremy Y',
    'Countrt',
    'Strxt',
    'Streandesetting',
    'contaem Ipsum Z',
    'Bathrough',
    'Streeword',
    'Countvervorem',
    'Strsum',
    'Strlectronic',
    'Countrxt',
    'Testipsum',
    'Street tummy Yv',
    'DummyTes',
    'Ahatsum',
    'Streetre',
    'Thisntry',
    'Astreetwibc',
    'WTBcdntrywhat'
  ]; // Add more locations if needed
  return locations[Math.floor(Math.random() * locations.length)];
};

const generateRandomAddress = () => {
  const addresses = [
    'Country Lorem ipsum',
    'Street Lorem dummy Y',
    'Country dummy text',
    'Str dummy text',
    'Street printing and typesetting',
    'containing Lorem Ipsum Z',
    'Band going through',
    'Street cites of the word',
    'Country Z lorem',
    'Str Country Lorem ipsum',
    'Street leap into electronic',
    'Country dummy text',
    'Test text Country Lorem ipsum',
    'Street test text Lorem dummy Y',
    'dummy textCountry Z',
    'A what Country Lorem ipsum',
    'Street Lorem dummy Y',
    'This Country Z',
    'Astreet wihis Country Abc',
    'TBcd his Country what'
  ];
  return addresses[Math.floor(Math.random() * addresses.length)];
};

// Function to generate a random website URL
const generateRandomWebsiteURL = () => {
  const domains = ['lorem.com', 'example.org', 'ipsum.net']; // Add more domains if needed
  return `www.${generateRandomName()
    .substring(0, 5)
    .toLowerCase()
    .replace(/\s+/g, '')}.${
    domains[Math.floor(Math.random() * domains.length)]
  }`;
};

export const mockedCompanies = [
  {
    hidden: true,
    company_name: 'Name example',
    location: 'Country example',
    materials: ['Test material', 'Whatamaterial'],
    address: 'Address details will be displayed here',
    website_url: 'www.example.example',
    phone_numbers: [
      '+49 (0)000 000 00000',
      '+49 (0)110 000 00048',
      '+49 (0)000 110 00220',
      '+49 (0)049 000 38000',
      '+49 (0)059 000 40001',
      '+49 (0)059 000 40301',
      '+49 (0)055 020 40008',
      '+49 (0)049 330 38000',
      '+49 (0)059 000 40003'
    ],
    contacts: [
      {
        email: 'example@www.example.de'
      },
      {
        email: 'example.ex@www.example.uk'
      },
      {
        email: 'testit@www.test.de'
      },
      {
        email: 'loremipsum.ex@www.example.de'
      }
    ],
    technologies: [
      'Example technology',
      'Whatatech',
      'Test technology',
      'Lorem tech',
      'Technology ro',
      'Testit'
    ],
    certification: ['Example certification'],
    n_machines: 40,
    employee_count: 6456,
    machines: [
      {
        brand: 'example brand',
        model: 'example model'
      }
    ]
  },
  {
    hidden: true,
    company_name: 'What Name example',
    location: 'wow Country example',
    materials: ['Material Ipsum', 'Test material'],
    address: 'Lorem details will be ipsum here',
    website_url: 'www.examplevwebrv.berb.example',
    phone_numbers: [
      '+49 (0)440 000 00000',
      '+49 (0)000 985 04500',
      '+49 (0)000 980 00000'
    ],
    contacts: [
      {
        email: 'example@www.example.de'
      },
      {
        email: 'examwerple@www.exvewample.de'
      }
    ],
    technologies: ['Example technology', 'lorem ipsum technology'],
    certification: [
      'what Example certification',
      'lorem ipsum Example certification'
    ],
    n_machines: 59,
    employee_count: 332,
    machines: [
      {
        brand: 'lorem ipsum brand',
        model: 'example lorem ipsum model'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    location: generateRandomLocation(),
    address: generateRandomAddress(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: ['+49 (0)440 000 033000', '+49 (0)0890 985 04500'],
    contacts: [
      {
        email: 'exweample@wvw.example.de'
      },
      {
        email: 'examwerplfqe@www.exvewample.de'
      }
    ],
    technologies: ['Lorem Ipsum xample technology', 'lorem ipsum technology'],
    certification: [
      'Lorem whatification',
      'Dummy lorem ipsum Example certification'
    ],
    n_machines: 103,
    employee_count: 54,
    machines: [
      {
        brand: 'vqe3w brand',
        model: 'example Okj'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    location: generateRandomLocation(),
    address: generateRandomAddress(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: ['+49 (0)0430 000 00341'],
    emails: ['jkfwefxample@www.gmail.de'],
    contacts: [
      {
        email: 'jkfwefxample@www.gmail.de'
      }
    ],
    technologies: ['What a tech', 'Lorem ipsumhat a tech'],
    certification: ['Example certification'],
    n_machines: 90,
    employee_count: 34,
    machines: [
      {
        brand: 'n brand',
        model: 'Bbkjbample VER'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: [
      '+49 (0)000 720 00000',
      '+49 (0)087 000 00000',
      '+49 (0)900 000 00000'
    ],
    contacts: [
      {
        email: 'shjxampfle@www.exvaerample.de'
      }
    ],
    technologies: ['Tech iExample technology'],
    certification: ['Sllrtification'],
    n_machines: 407,
    employee_count: 634,
    machines: [
      {
        brand: 'exaeqvrvmple brand',
        model: 'EBhv model'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    contacts: [
      {
        email: 'example@www.example.de'
      }
    ],
    phone_numbers: ['+49 (0)033 000 983000'],
    technologies: ['Example technology'],
    certification: ['Example certification'],
    n_machines: 209,
    employee_count: 134,
    machines: [
      {
        brand: 'gqergqerg brand',
        model: 'QWbv model'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: ['+49 (0)0423 984 00900'],
    contacts: [
      {
        email: 'exr2ample@www.examrrrple.de'
      },
      {
        email: 'exrw2ample@www.exafmrrrple.com'
      }
    ],
    technologies: ['Example technology', '32 fwExample technology'],
    certification: ['what dummy text'],
    n_machines: 68,
    employee_count: 189,
    machines: [
      {
        brand: 'qerfg brand',
        model: 'example qerv'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: ['+39 (0)000 9000 009830'],
    contacts: [
      {
        email: '1jhexample@www.exampr23le.de'
      },
      {
        email: '1jhvple@www.xampr3le.de'
      }
    ],
    technologies: ['Dummy', 'fwe'],
    certification: ['fwqef certification'],
    n_machines: 54,
    employee_count: 2998,
    machines: [
      {
        brand: 'vqewr brand',
        model: 'example qv'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    contacts: [
      {
        email: 'exvwqevample@www.example.de'
      },
      {
        email: 'fwbele@www.example.de'
      }
    ],
    phone_numbers: ['+26 (0)087 000 00098', '+39 (0)098 000 09000'],
    technologies: ['Example technology', 'TRes fwExample technology'],
    certification: ['Hbh certification', 'certification'],
    n_machines: 445,
    employee_count: 50,
    machines: [
      {
        brand: 'GJhv brand',
        model: 'vqwe model'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: ['+49 (0)370 000 098000'],
    contacts: [
      {
        email: 'bkhjxample@www.em.de'
      }
    ],
    emails: ['bkhjxample@www.em.de'],
    technologies: ['TEST technology'],
    certification: ['Lorem'],
    n_machines: 4,
    employee_count: 39,
    machines: [
      {
        brand: 'Lorem brand',
        model: 'Ipsum h model'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: ['+49 (0)000 000 80000'],
    contacts: [
      {
        email: 'example@www.exghvple.de'
      },
      {
        email: 'fwbele@www.example.de'
      }
    ],
    technologies: ['Exam32le technology'],
    certification: ['Exam34534e certification'],
    n_machines: 487,
    employee_count: 3984,
    machines: [
      {
        brand: 'vewrv brand',
        model: 'TY nwr model'
      }
    ]
  },
  {
    hidden: true,
    company_name: generateRandomName(),
    address: generateRandomAddress(),
    location: generateRandomLocation(),
    materials: generateRandomMeterials(),
    website_url: generateRandomWebsiteURL(),
    phone_numbers: ['+49 (0)780 200 03089'],
    contacts: [
      {
        email: 'ghvhexaple@www.mple.de'
      }
    ],
    technologies: ['EGjhvjvxample technology'],
    certification: ['ver H certification'],
    n_machines: 5,
    employee_count: 4555,
    machines: [
      {
        brand: 'Hbvf brand',
        model: 'lko pj'
      }
    ]
  }
];
