import { MutableRefObject } from 'react';
import { HttpStatusCode } from 'axios';
import { Chat } from 'entities/Chat.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { Message } from 'entities/Message.entity';
import { FeedbackTypes, MessageTypes } from 'enums';
import queryString from 'query-string';

import { ApiBase } from '../ApiBase';

export type GetChatsQuery = {
  includeArchived?: boolean;
};

class ChatsApi extends ApiBase {
  createChat({ subject }: { subject?: string }) {
    return this.client.post<Chat>('', {
      subject
    });
  }

  fetchChats(chatsQuery?: GetChatsQuery) {
    const query = chatsQuery
      ? queryString.stringify(chatsQuery, {
          arrayFormat: 'comma'
        })
      : '';

    return this.client.get<Chat[]>(
      query
        ? `${process.env.REACT_APP_BASE_URL}/chats?${query}`
        : `${process.env.REACT_APP_BASE_URL}/chats`,
      { baseURL: '' }
    );
  }

  // eslint-disable-next-line class-methods-use-this
  requestProductsData(payload: URLSearchParams) {
    return this.client.post(
      `${process.env.REACT_APP_BASE_URL}/salesforce/contact-form`,
      payload,
      {
        baseURL: '',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
  }

  regenerateChatMessage(
    chatId: string,
    messageId: string,
    abortController: MutableRefObject<AbortController | null>,
    setMessageData: (data: {
      id?: string;
      messageId?: string;
      type: MessageTypes;
      statement: string;
    }) => void
  ) {
    return this.client.post<string>(
      `/${chatId}/messages/${messageId}/regenerate`,
      undefined,
      {
        signal: abortController.current?.signal,
        responseType: 'stream',
        onDownloadProgress: (data) => {
          if (
            data.event.currentTarget.status === HttpStatusCode.Ok ||
            data.event.currentTarget.status === HttpStatusCode.Created
          ) {
            const id = data.event.currentTarget.getResponseHeader('Answerid');
            const messageId =
              data.event.currentTarget.getResponseHeader('Messageid');
            const type = MessageTypes.answer;
            const statement = data.event.currentTarget.response;

            setMessageData({
              id,
              type,
              messageId,
              statement
            });
          }
        }
      }
    );
  }

  createMessage(
    chatId: string,
    message: string,
    abortController: MutableRefObject<AbortController | null>,
    setMessageData: (data: {
      id?: string;
      messageId?: string;
      type: MessageTypes;
      statement: string;
    }) => void
  ) {
    return this.client.post<string>(
      `/${chatId}/messages`,
      {
        message
      },
      {
        signal: abortController.current?.signal,
        responseType: 'stream',
        onDownloadProgress: (data) => {
          if (
            data.event.currentTarget.status === HttpStatusCode.Ok ||
            data.event.currentTarget.status === HttpStatusCode.Created
          ) {
            const id = data.event.currentTarget.getResponseHeader('Answerid');
            const messageId =
              data.event.currentTarget.getResponseHeader('Messageid');
            const type = MessageTypes.answer;
            const statement = data.event.currentTarget.response;

            setMessageData({
              id,
              type,
              messageId,
              statement
            });
          }
        }
      }
    );
  }

  fetchMessagesByChatId(chatId: string) {
    return this.client.get<Message[]>(`/${chatId}/messages`);
  }

  fetchChatById(chatId: string) {
    return this.client.get<Chat>(`/${chatId}`);
  }

  fetchChatDetailsById(chatId: string) {
    return this.client.get<Chat>(`/${chatId}?raw=true`);
  }

  getFollowUpQuestions(
    chatId: string,
    messageId: string,
    signal: AbortSignal,
    setMessageData: (data: {
      id?: string;
      messageId?: string;
      type: MessageTypes;
      statement: string;
    }) => void
  ) {
    return this.client.get<string>(
      `/${chatId}/messages/${messageId}/followup-questions`,
      {
        signal,
        responseType: 'stream',
        onDownloadProgress: (data) => {
          if (
            data.event.currentTarget.status === HttpStatusCode.Ok ||
            data.event.currentTarget.status === HttpStatusCode.Created
          ) {
            const id = data.event.currentTarget.getResponseHeader('Followupid');
            const messageId =
              data.event.currentTarget.getResponseHeader('Messageid');
            const type = MessageTypes.followup_questions;
            const statement = data.event.currentTarget.response;

            setMessageData({
              id,
              type,
              messageId,
              statement
            });
          }
        }
      }
    );
  }

  archiveChat(chatId: string, data: { isArchived: boolean }) {
    return this.client.patch<Chat>(`/${chatId}/archive`, data);
  }

  updateChat(chatId: string, data: { title: string }) {
    return this.client.patch<Chat>(`/${chatId}`, data);
  }

  updateChatWidget({
    chatId,
    messageId,
    widgetId,
    data
  }: {
    chatId: string;
    messageId: string;
    widgetId: DashboardComponent['id'];
    data: { isHidden: boolean };
  }) {
    return this.client.patch<void>(
      `/${chatId}/messages/${messageId}/widgets/${widgetId}/hide`,
      data
    );
  }

  rateChatMessage({
    text,
    chatId,
    messageId,
    feedbackType
  }: {
    text?: string;
    chatId: string;
    messageId: string;
    feedbackType: FeedbackTypes;
  }) {
    return this.client.post<void>(`/${chatId}/messages/${messageId}/rate`, {
      text,
      feedbackType
    });
  }
}

const instance = new ChatsApi('/chats');

export { instance as ChatsApi };
