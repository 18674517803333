import {
  ChangeEvent,
  FC,
  KeyboardEventHandler,
  useEffect,
  useMemo,
  useRef
} from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, IconButton, OutlinedInput } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { ReactComponent as SendIcon } from 'assets/SendIcon.svg';
import cn from 'classnames';
import { Tooltip } from 'components';
import { useUserInfo } from 'hooks/api';
import { useGlobalShortcutsContext } from 'hooks/useGlobalShortcutsContext';

import styles from './styles.module.scss';

const inputProps = {
  'data-testid': 'chat-input'
};

interface Props {
  value: string;
  isLoading: boolean;
  disabled?: boolean;
  onSubmit: () => void;
  setValue: (value: string) => void;
}

export const ChatInput: FC<Props> = ({
  value,
  onSubmit,
  disabled,
  setValue,
  isLoading
}) => {
  const { t } = useTranslation();
  const { data: userInfo } = useUserInfo();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { chatInput, setChatInput } = useGlobalShortcutsContext();

  const tooltip = useMemo(() => {
    if (userInfo?.deactivatedAt) {
      return t('Page.Chat.DeactivatedAccountTooltip');
    }
    return '';
  }, [t, userInfo?.deactivatedAt]);

  useEffect(() => {
    if (inputRef.current && !chatInput) {
      setChatInput(inputRef.current);
    }

    return () => {
      setChatInput(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetValue = (e: ChangeEvent<HTMLInputElement>) =>
    setValue(e.target.value);

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const withoutAdditionalKeys =
      !e.ctrlKey && !e.shiftKey && !e.altKey && !e.metaKey;

    if (e.code === 'Enter' && withoutAdditionalKeys) {
      onSubmit();
    }
  };

  const onClick = () => {
    if (disabled) {
      return;
    }
    onSubmit();
  };

  const renderEndAdornment = () => {
    if (isLoading) {
      return (
        <CircularProgress
          thickness={6}
          className={styles.progress}
          data-testid="chat-progress"
        />
      );
    }

    return (
      <IconButton
        edge="end"
        onClick={onClick}
        data-testid="send-button"
        className={cn(styles.submit, disabled && styles.disabled)}
      >
        <SendIcon />
      </IconButton>
    );
  };

  return (
    <FormControl hiddenLabel variant="outlined" className={styles.container}>
      <Tooltip placement="top" title={tooltip} disabled={!disabled}>
        <div>
          <OutlinedInput
            id="outlined-adornment-search"
            type="text"
            value={value}
            inputRef={inputRef}
            inputProps={inputProps}
            className={cn(
              styles.input,
              (isLoading || disabled) && styles.disabled
            )}
            onChange={handleSetValue}
            onKeyDown={handleKeyPress}
            disabled={isLoading || disabled}
            placeholder={t('Page.Chat.Placeholder')}
            endAdornment={
              <InputAdornment position="end">
                {renderEndAdornment()}
              </InputAdornment>
            }
          />
        </div>
      </Tooltip>
    </FormControl>
  );
};
