import { FC, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import styles from './styles.module.scss';

interface Props {
  title: string;
  className?: string;
}

export const CommonTooltip: FC<Props> = ({ title, className }) => {
  const [open, setOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const textElementRef = useRef<HTMLInputElement | null>(null);

  const compareSize = () => {
    if (!textElementRef?.current) {
      return;
    }

    const compare =
      textElementRef.current.scrollHeight >
        textElementRef.current.clientHeight ||
      textElementRef.current?.scrollWidth > textElementRef.current?.clientWidth;
    setShowTooltip(compare);
  };
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => window.removeEventListener('resize', compareSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => showTooltip && setOpen(true);

  if (isMobile) {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div className={styles['full-width']}>
          <Tooltip
            open={open}
            title={title}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="bottom-start"
            TransitionComponent={Zoom}
            classes={{
              tooltip: styles.tooltip
            }}
            onClose={handleTooltipClose}
          >
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <h2
              ref={textElementRef}
              className={className}
              onClick={handleTooltipOpen}
            >
              {title}
            </h2>
          </Tooltip>
        </div>
      </ClickAwayListener>
    );
  }

  return (
    <Tooltip
      title={title}
      placement="bottom-start"
      TransitionComponent={Zoom}
      classes={{
        tooltip: styles.tooltip
      }}
      disableHoverListener={!showTooltip}
    >
      <h2 ref={textElementRef} className={className}>
        {title}
      </h2>
    </Tooltip>
  );
};
