import { useState } from 'react';
import { Navigate, Route, Routes as BrowserRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  BetaUsersInformationModal,
  FreeUsersInformationModal,
  GlobalShortcutsModal,
  Header,
  NewUsersInformationModal,
  UnpaidSubscriptionModal
} from 'components';
import { CloudExperience } from 'components/CloudExperience';
import { NewDevelopmentsToast } from 'components/NewDevelopmentsToast';
import { NoMatch } from 'components/NoMatch';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { SeoSettings } from 'components/SeoSettings';
import { WithVerifiedUserRoute } from 'components/WithVerifiedUserRoute/WithVerifiedUserRoute';
import { Routes } from 'enums/Routes';
import { useScrollPositionReset } from 'hooks';
import { useGlobalShortcuts } from 'hooks/useGlobalShortcuts';
import { useHotjarAnalytics } from 'hooks/useHotjarAnalytics';
import { useMixpanelAnalytics } from 'hooks/useMixpanelAnalytics';
import { usePreferredLanguage } from 'hooks/usePreferredLanguage';
import { useUnloadHandlers } from 'hooks/useUnloadHandlers';
import { AuthPage } from 'views/AuthPage';
import { Confirm } from 'views/Confirm';
import { Conversation } from 'views/Conversation';
import { Dashboard } from 'views/Dashboard';
import { ForgotPassword } from 'views/ForgotPassword';
import { NewDevelopments } from 'views/NewDevelopments';
import { NewPassword } from 'views/NewPassword';
import { OneTimePaymentCheckout } from 'views/OneTimePaymentCheckout';
import { Profile } from 'views/Profile';
import { ResetPasswordConfirm } from 'views/ResetPasswordConfirm';
import { SignIn } from 'views/SignIn';
import { SignUp } from 'views/SignUp';
import { SubscriptionCheckout } from 'views/SubscriptionCheckout';
import { SubscriptionConfirm } from 'views/SubscriptionConfirm';
import { SubscriptionPlan } from 'views/SubscriptionPlan';
import { SubscriptionUpgradeConfirm } from 'views/SubscriptionUpgradeConfirm';
import { Unsubscribe } from 'views/Unsubscribe';
import { UpdateEmailConfirm } from 'views/UpdateEmailConfirm';
import { VerifyUser } from 'views/VerifyUser';

import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(BrowserRoutes);

const App = () => {
  useUnloadHandlers();
  useScrollPositionReset();
  usePreferredLanguage();
  useMixpanelAnalytics();
  useHotjarAnalytics();
  useGlobalShortcuts();

  const [isAnimationInitialized, setIsAnimationInitialized] = useState(false);

  return (
    <>
      <SeoSettings />
      <CloudExperience onLoad={setIsAnimationInitialized} />
      <BetaUsersInformationModal />
      <NewUsersInformationModal />
      <UnpaidSubscriptionModal />
      <GlobalShortcutsModal />
      <FreeUsersInformationModal />
      <NewDevelopmentsToast />

      {isAnimationInitialized && (
        <div className={styles.app}>
          <Header />

          <SentryRoutes>
            <Route path={Routes.Root}>
              <Route
                path={Routes.PublicIndustryChat}
                element={<Conversation />}
              />

              <Route
                element={
                  <ProtectedRoute
                    forAuthenticated={false}
                    redirectPath={Routes.NewChat}
                  />
                }
              >
                <Route path={Routes.Auth} element={<AuthPage />} />
                <Route path={Routes.Login} element={<SignIn />} />
                <Route path={Routes.Confirm} element={<Confirm />} />
                <Route path={Routes.Registration} element={<SignUp />} />
                <Route
                  path={Routes.ForgotPassword}
                  element={<ForgotPassword />}
                />
                <Route
                  path={Routes.ConfirmRestorePassword}
                  element={<ResetPasswordConfirm />}
                />
              </Route>
              <Route
                path={Routes.UpdateEmailConfirm}
                element={<UpdateEmailConfirm />}
              />
              <Route path={Routes.Unsubscribe} element={<Unsubscribe />} />
              <Route path={Routes.RestorePassword} element={<NewPassword />} />
              <Route element={<ProtectedRoute redirectPath={Routes.Auth} />}>
                <Route
                  element={
                    <WithVerifiedUserRoute redirectPath={Routes.VerifyUser} />
                  }
                >
                  <Route
                    path={Routes.IndustryChat}
                    element={<Conversation />}
                  />
                  <Route path={Routes.Dashboard} element={<Dashboard />} />
                  <Route path={Routes.NewChat} element={<Conversation />} />
                  <Route path={Routes.Chat} element={<Conversation />} />
                  <Route path={Routes.Profile} element={<Profile />} />
                  <Route
                    path={Routes.SubscriptionPlans}
                    element={<SubscriptionPlan />}
                  />
                  <Route
                    path={Routes.OneTimePaymentCheckout}
                    element={<OneTimePaymentCheckout />}
                  />
                  <Route
                    path={Routes.SubscriptionConfirm}
                    element={<SubscriptionConfirm />}
                  />
                  <Route
                    path={Routes.SubscriptionUpgradeConfirm}
                    element={<SubscriptionUpgradeConfirm />}
                  />
                  <Route
                    path={Routes.NewDevelopments}
                    element={<NewDevelopments />}
                  />
                  <Route
                    path={Routes.Checkout}
                    element={<SubscriptionCheckout />}
                  />
                  <Route
                    index
                    element={<Navigate replace to={Routes.NewChat} />}
                  />
                </Route>

                <Route path={Routes.VerifyUser} element={<VerifyUser />} />
              </Route>

              <Route path="*" element={<NoMatch link={Routes.NewChat} />} />
            </Route>
          </SentryRoutes>
        </div>
      )}
    </>
  );
};

export default App;
