import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import cn from 'classnames';
import { Modal, SubTitle } from 'components';
import { Routes } from 'enums';
import { useUpdateUserInfo } from 'hooks/api/useUpdateUserInfo';
import { useUserInfo } from 'hooks/api/useUserInfo';

import styles from './styles.module.scss';

// TODO: Remove after all Beta users will receive see that modal
export const BetaUsersInformationModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: userInfo } = useUserInfo();
  const { mutate: updateUserInfo } = useUpdateUserInfo(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => {
    setIsOpen(false);
    updateUserInfo({ isFirstLogin: false });
  };

  const handleUpgradeButtonClick = () => {
    handleClose();
    navigate(Routes.SubscriptionPlans);
  };

  useEffect(() => {
    setIsOpen(
      Boolean(userInfo?.isBeta && userInfo?.isFirstLogin && userInfo?.lastName)
    );
  }, [userInfo]);

  return (
    <Modal
      title={t('Common.BetaUsersInformationModal.Title')}
      titleClassName={styles.title}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <SubTitle className={styles.subtitle}>
        {t('Common.BetaUsersInformationModal.Subtitle')}
      </SubTitle>
      <Button
        size="medium"
        type="submit"
        variant="contained"
        className={cn(styles.button, 'brilliance')}
        onClick={handleUpgradeButtonClick}
      >
        {t('Common.BetaUsersInformationModal.Button')}
      </Button>
    </Modal>
  );
};
