import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, IconButton } from '@mui/material';
import { ReactComponent as ThumbDownIcon } from 'assets/thumbs-down.svg';
import { ReactComponent as ThumbUpIcon } from 'assets/thumbs-up.svg';
import cn from 'classnames';
import { FeedbackModal, Tooltip } from 'components';
import { Message } from 'entities/Message.entity';
import { FeedbackTypes, queryKeys, Routes } from 'enums';
import { MessageTypes } from 'enums/MessageTypes.enum';
import { useRateChatMessage, useUserCoins, useUserInfo } from 'hooks/api';
import { queryClient } from 'index';
import mixpanel, { MixpanelEvents } from 'mixpanel';

import { ChatStatement } from '../ChatStatement';

import styles from './styles.module.scss';

interface Props {
  data: Message;
  isLoading?: boolean;
  isLastAnswer?: boolean;
  isMessageSending?: boolean;
  isRateButtonHidden?: boolean;
  interruptMessage?: () => void;
  isDashboardButtonHidden?: boolean;
  isRegenerateMessageSending?: boolean;
  onRequestMoreDataClick?: () => void;
  onSuggestionClick?: (text: string) => void;
  regenerateChatMessage?: (messageId: string) => void;
  onShowDashboardClick?: (dashboardId: string) => void;
}

export const ChatMessage: FC<Props> = ({
  data: {
    id,
    type,
    statement,
    hasFeedback,
    isPaid,
    isNotFull,
    messageId,
    suggestions,
    isInterrupted,
    dashboardId,
    hasUpgradeButton,
    requestMoreDataVisible = true
  },
  isLoading,
  isMessageSending,
  isLastAnswer,
  interruptMessage,
  isRegenerateMessageSending,
  onShowDashboardClick,
  regenerateChatMessage,
  isDashboardButtonHidden,
  isRateButtonHidden,
  onSuggestionClick,
  onRequestMoreDataClick
}) => {
  const messageContainerRef = useRef<HTMLDivElement | null>(null);

  const [givenFeedback, setGivenFeedback] = useState<FeedbackTypes | null>(
    null
  );

  const { chatId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: userInfo } = useUserInfo();
  const { data: userCoins } = useUserCoins();

  const { mutate: rateChatMessage } = useRateChatMessage(
    chatId || '',
    messageId || ''
  );

  const hasError = id?.includes('error');

  const openFeedbackModal = (feedback: FeedbackTypes | null) => {
    if (!hasFeedback && !isMessageSending && !isRegenerateMessageSending) {
      setGivenFeedback(feedback);
    }
  };

  const closeFeedbackModal = () => {
    if (!hasFeedback) {
      setGivenFeedback(null);
    }
  };

  const submitFeedback = (feedback: FeedbackTypes, feedbackText?: string) => {
    mixpanel?.track(MixpanelEvents.AnswerFeedback, {
      Feedback: feedback,
      'Feedback Text': feedbackText,
      'Chat ID': chatId,
      'Message ID': messageId,
      Text: statement
    });
    rateChatMessage({ text: feedbackText, feedbackType: feedback });
  };

  const handleShowDashboardClick = () => {
    if (onShowDashboardClick && dashboardId) {
      onShowDashboardClick(dashboardId);
    }
  };

  const regenerateMessage = (messageId?: string) => () => {
    if (regenerateChatMessage && messageId) {
      regenerateChatMessage(messageId);
    }
  };

  const handleUpgradeToPremiumClick = () => {
    navigate(Routes.SubscriptionPlans, {
      state: { scrollToTop: true }
    });
  };

  const stopMessage = () => {
    if (!chatId) {
      return;
    }

    if (interruptMessage) {
      interruptMessage();
    }

    const prevMessages: Message[] | undefined = queryClient.getQueryData(
      queryKeys.chatMessages(chatId)
    );

    if (!prevMessages) return;

    const updatedMessages = prevMessages?.map((message) => {
      if (message.messageId === messageId) {
        return {
          ...message,
          isNotFull: true
        };
      }
      return message;
    });

    if (updatedMessages?.length) {
      queryClient.setQueryData(queryKeys.chatMessages(chatId), updatedMessages);
    }
  };

  const hideRateButton =
    isMessageSending || isRegenerateMessageSending || isRateButtonHidden;
  const showDashboardButtonVisible =
    !!dashboardId &&
    !isDashboardButtonHidden &&
    !isMessageSending &&
    !isRegenerateMessageSending &&
    !isNotFull;
  const restartGenerationButtonVisible =
    isLastAnswer &&
    !isMessageSending &&
    !isRegenerateMessageSending &&
    isNotFull;
  const stopGenerationButtonVisible =
    isLastAnswer && (isMessageSending || isRegenerateMessageSending);
  const disableDashboard =
    !isPaid &&
    (userInfo?.deactivatedAt || (userInfo?.isFreeUser && !userCoins?.count));
  const actionsVisible =
    (requestMoreDataVisible && type === MessageTypes.answer) ||
    showDashboardButtonVisible ||
    restartGenerationButtonVisible ||
    stopGenerationButtonVisible;

  return (
    <div
      ref={messageContainerRef}
      data-testid="chat-message"
      className={cn(styles.message, styles[type])}
    >
      {!!givenFeedback && (
        <FeedbackModal
          feedback={givenFeedback}
          onClose={closeFeedbackModal}
          onSubmitFeedback={submitFeedback}
        />
      )}
      <ChatStatement
        data={{ type, statement }}
        showCaret={isLastAnswer && isLoading}
      >
        <>
          {isInterrupted &&
            (type === MessageTypes.answer ||
              type === MessageTypes.followup_questions) && (
              <span className={styles['interrupted-note']}>
                {t('Page.Chat.InterruptedNote')}
              </span>
            )}

          {hasUpgradeButton && (
            <Button
              size="medium"
              color="primary"
              variant="contained"
              className={cn(styles.button, 'brilliance')}
              onClick={handleUpgradeToPremiumClick}
            >
              {t('Page.Chat.UpgradeToPremium')}
            </Button>
          )}

          {!!suggestions?.length && onSuggestionClick && (
            <div className={styles.suggestions}>
              {suggestions.map((suggestion) => (
                <div
                  onClick={() => !isLoading && onSuggestionClick(suggestion)}
                  className={styles.suggestion}
                  key={suggestion}
                >
                  {suggestion}
                </div>
              ))}
            </div>
          )}

          <div
            className={cn(
              styles.footer,
              !actionsVisible && styles['end-aligned']
            )}
          >
            {actionsVisible && (
              <div className={styles.actions}>
                <Button
                  size="medium"
                  color="primary"
                  variant="contained"
                  onClick={onRequestMoreDataClick}
                  className={cn(styles['action-button'])}
                >
                  {t('Page.Chat.RequestAdditionalData')}
                </Button>

                {showDashboardButtonVisible && (
                  <Tooltip
                    title={
                      !userCoins?.count
                        ? t('Common.FreeSubscriptionFullDashboardButtonTooltip')
                        : t('Common.UnlockCoinsModal.DeactivatedAccountTooltip')
                    }
                    disabled={!disableDashboard}
                    className={styles.tooltip}
                  >
                    <span>
                      <Button
                        size="medium"
                        color="primary"
                        variant="contained"
                        disabled={!!disableDashboard}
                        className={cn(styles['action-button'], 'brilliance')}
                        onClick={handleShowDashboardClick}
                      >
                        {t(
                          !isPaid && userInfo?.isFreeUser
                            ? 'Page.Chat.ForDashboard'
                            : 'Page.Chat.ForPaidDashboard'
                        )}
                      </Button>
                    </span>
                  </Tooltip>
                )}

                {stopGenerationButtonVisible && (
                  <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    onClick={stopMessage}
                    className={cn(styles['action-button'], 'brilliance')}
                  >
                    {t('Page.Chat.StopGeneration')}
                  </Button>
                )}

                {restartGenerationButtonVisible && (
                  <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    onClick={regenerateMessage(messageId)}
                    className={cn(styles['action-button'], 'brilliance')}
                  >
                    {t('Page.Chat.RestartGeneration')}
                  </Button>
                )}
              </div>
            )}

            {type === MessageTypes.answer && !hideRateButton && !hasError && (
              <div className={styles['rate-container']}>
                <span className={styles.label}>
                  {t('Page.Chat.RateAnswer')}
                </span>
                <Tooltip
                  title={t('Common.FeedbackModal.DisabledTooltip')}
                  disabled={!hasFeedback}
                  className={styles.tooltip}
                >
                  <IconButton
                    edge="end"
                    className={cn(
                      styles['rate-container-button'],
                      hasFeedback && styles.disabled
                    )}
                    onClick={() => openFeedbackModal(FeedbackTypes.NEGATIVE)}
                  >
                    <ThumbDownIcon className={styles.icon} />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={t('Common.FeedbackModal.DisabledTooltip')}
                  disabled={!hasFeedback}
                  className={styles.tooltip}
                >
                  <IconButton
                    edge="end"
                    className={cn(
                      styles['rate-container-button'],
                      hasFeedback && styles.disabled
                    )}
                    onClick={() => openFeedbackModal(FeedbackTypes.POSITIVE)}
                  >
                    <ThumbUpIcon className={styles.icon} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
        </>
      </ChatStatement>
    </div>
  );
};
