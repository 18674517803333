import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { ReactComponent as GieniIcon } from 'assets/GieniIconInverted.svg';
import { ReadMore } from 'components';
import { Message } from 'entities/Message.entity';
import { MessageTypes } from 'enums/MessageTypes.enum';
import { useUserAvatar, useUserInfo } from 'hooks/api';
import Markdown from 'markdown-to-jsx';

import styles from './styles.module.scss';

interface Props {
  data: Partial<Message>;
  children?: ReactNode;
  showCaret?: boolean;
  showAvatar?: boolean;
  maxLettersAmount?: number;
}

export const ChatStatement: FC<Props> = ({
  children,
  maxLettersAmount,
  data: { type, statement },
  showCaret,
  showAvatar = true
}) => {
  const [userAvatarUrl, setUserAvatarUrl] = useState<string | undefined>();

  const { data: userInfo } = useUserInfo();
  const { data: userAvatar } = useUserAvatar();

  useEffect(() => {
    if (userAvatar) {
      const imageUrl = URL.createObjectURL(userAvatar);

      setUserAvatarUrl(imageUrl);
    } else {
      setUserAvatarUrl(undefined);
    }

    return () => {
      if (userAvatarUrl) {
        URL.revokeObjectURL(userAvatarUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAvatar]);

  const finalStatement = useMemo(() => {
    let processedStatement = statement;

    processedStatement = processedStatement?.replace(
      /(```|markdown|None$)/g,
      ''
    ) as string;

    return processedStatement;
  }, [statement]);

  const getUserNameFirstCharacter = () => {
    const name = userInfo?.firstName || userInfo?.email || '';

    return name.charAt(0).toUpperCase();
  };

  const getUserAvatar = () => {
    return userAvatarUrl ? (
      <img
        alt="avatar"
        src={userAvatarUrl}
        className={styles['avatar-image']}
      />
    ) : (
      <span>{getUserNameFirstCharacter()}</span>
    );
  };

  return (
    <div className={styles['message-body']}>
      {showAvatar && (
        <div className={styles.avatar}>
          {type === MessageTypes.question ? getUserAvatar() : <GieniIcon />}
        </div>
      )}

      <div className={styles['content-container']}>
        <div className={styles.content}>
          {maxLettersAmount ? (
            <ReadMore
              text={finalStatement}
              maxLettersAmount={maxLettersAmount}
            />
          ) : (
            <Markdown>{finalStatement}</Markdown>
          )}
          {showCaret && <div className={styles.caret} />}
        </div>
        {children}
      </div>
    </div>
  );
};
