import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchDashboardById } from 'actions/dashboardActions';
import { queryKeys } from 'enums';

export const useIsDashboardAccessible = () => {
  const { dashboardId } = useParams();

  const { data: dashboardData } = useQuery({
    queryKey: queryKeys.dashboardDetails(dashboardId!),
    queryFn: () => fetchDashboardById(dashboardId as string),
    refetchOnWindowFocus: false,
    enabled: !!dashboardId
  });

  return dashboardData?.isPaid;
};
