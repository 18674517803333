import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutFooter, Loader } from 'components';
import { SubscriptionCycles } from 'enums';
import { useClientSecret } from 'hooks/api/useClientSecret';

import styles from './styles.module.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');
export const SubscriptionCheckout: FC = () => {
  const [searchParams] = useSearchParams();
  const [clientSecret, setClientSecret] = useState('');

  const billingCycle = searchParams.get('billing-cycle');
  const page = searchParams.get('page');

  const onSuccess = (secret: string) => {
    setClientSecret(secret);
  };

  const { mutate: getSubscriptionSecret, isPending } =
    useClientSecret(onSuccess);

  useEffect(() => {
    if (billingCycle) {
      getSubscriptionSecret({
        period: billingCycle as SubscriptionCycles,
        page
      });
    }
  }, [billingCycle, getSubscriptionSecret, page]);

  const options = { clientSecret };

  return (
    <>
      <div className={styles.container}>
        {isPending && <Loader loaderClassName={styles.loader} />}
        {clientSecret && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
      <CheckoutFooter />
    </>
  );
};
