import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';
import cn from 'classnames';
import { Loader, Tooltip } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { queryKeys } from 'enums';
import { Routes } from 'enums/Routes';
import { useUserCoins, useUserInfo } from 'hooks/api';
import { useNestedDashboards } from 'hooks/api/useNestedDashboards';
import { queryClient } from 'index';

import styles from './styles.module.scss';

interface Props {
  data: DashboardData;
}

export const ChatCardCollapse: FC<Props> = ({ data }) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const [nestedDashboards, setNestedDashboards] = useState<DashboardData[]>([]);

  const prevNestedDashboards: DashboardData[] | undefined =
    queryClient.getQueryData(queryKeys.nestedDashboards(data.id));

  const {
    isPending: isNestedDashboardsFetching,
    mutate: fetchNestedDashboards
  } = useNestedDashboards(setNestedDashboards);

  useEffect(() => {
    if (!expanded) {
      return;
    }

    if (!prevNestedDashboards) {
      fetchNestedDashboards({
        dashboardId: data.id
      });
    } else {
      setNestedDashboards(prevNestedDashboards);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevNestedDashboards]);

  const { data: userInfo } = useUserInfo();
  const { data: userCoins } = useUserCoins();

  const expandChatCard = useCallback(() => {
    setExpanded(true);

    if (!prevNestedDashboards) {
      fetchNestedDashboards({
        dashboardId: data.id
      });
    } else {
      setNestedDashboards(prevNestedDashboards);
    }
  }, [prevNestedDashboards, fetchNestedDashboards, data.id]);

  const collapseChatCard = useCallback(() => setExpanded(false), []);

  const renderDashboardIndicator = (isPaid?: boolean | null) => (
    <Tooltip
      enterTouchDelay={0}
      placement="right"
      className={styles['tooltip-indicator']}
      title={
        isPaid
          ? t('Common.UnlockedDashboardTooltip')
          : t('Common.LockedDashboardTooltip')
      }
    >
      <div className={cn(styles.indicator, isPaid && styles.filled)} />
    </Tooltip>
  );

  const dashboardLink = Routes.Dashboard.replace(':dashboardId', data.id);

  return (
    <>
      <div className={styles.content}>
        <Link to={dashboardLink} className={styles.label}>
          {renderDashboardIndicator(data.isPaid)}
          <Tooltip
            disabled={userInfo?.isPremiumUser || data.isPaid}
            enterTouchDelay={0}
            placement="bottom"
            title={
              userCoins?.count
                ? t('Common.LockedDashbordChargeTooltip')
                : t('Common.FreeSubscriptionFullDashboardButtonTooltip')
            }
          >
            <div className={cn(styles['label-text'], 'overflowed-text')}>
              {data.title}
            </div>
          </Tooltip>
        </Link>
        {data.hasNestedDashboards && (
          <div
            data-testid="expand"
            onClick={expanded ? collapseChatCard : expandChatCard}
          >
            <ExpandMoreIcon
              className={cn(styles['arrow-icon'], expanded && styles.expanded)}
            />
          </div>
        )}
      </div>
      <Collapse
        unmountOnExit
        in={expanded}
        timeout={{
          enter: 0
        }}
        className={styles.rows}
      >
        {isNestedDashboardsFetching ? (
          <Loader />
        ) : (
          (nestedDashboards || []).map((nestedDashboard) => {
            const link = Routes.Dashboard.replace(
              ':dashboardId',
              nestedDashboard.id
            );
            const isDisabledOption =
              !nestedDashboard.isPaid &&
              !userCoins?.count &&
              userInfo?.isFreeUser;

            return (
              <div key={nestedDashboard.id} className={styles.row}>
                <Link
                  to={link}
                  data-testid="question"
                  className={cn(
                    styles.question,
                    isDisabledOption && styles.disabled
                  )}
                >
                  <div
                    data-testid="question"
                    className={cn(styles['question-text'], 'overflowed-text')}
                  >
                    {nestedDashboard.title}
                  </div>
                </Link>
              </div>
            );
          })
        )}
      </Collapse>
    </>
  );
};
