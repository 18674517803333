import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import cn from 'classnames';
import { MoreDataModal } from 'views/Conversation/MoreDataModal';

import styles from './styles.module.scss';

export const CRMBoosterAd = () => {
  const { t } = useTranslation();
  const [isMoreDataModalOpen, setMoreDataModalOpen] = useState(false);

  const defaultSelectedProducts = [
    t('Common.MoreDataModal.Products.CRMBooster')
  ];

  const handleCtaClick = () => {
    setMoreDataModalOpen(true);
  };

  const handleCloseModal = () => {
    setMoreDataModalOpen(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>
          <h2 className={styles.title}>
            {t('Page.Dashboard.CRMBoosterAd.Title')}
          </h2>
          <span className={styles.subtitle}>
            {t('Page.Dashboard.CRMBoosterAd.Subtitle')}
          </span>
        </div>

        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleCtaClick}
          className={cn(styles.cta, 'brilliance')}
        >
          {t('Page.Dashboard.CRMBoosterAd.Cta')}
        </Button>
      </div>

      <MoreDataModal
        isOpen={isMoreDataModalOpen}
        onClose={handleCloseModal}
        defaultSelectedProducts={defaultSelectedProducts}
      />
    </>
  );
};
