import { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Routes } from 'enums';
import { LocalStorage } from 'services/LocalStorage';
import {
  APP_UI_LANGUAGE,
  CURRENT_USER_EMAIL_KEY,
  SHOW_LOGIN_MODAL,
  SHOW_UNPAID_SUBSCRIPTION_MODAL
} from 'utils/constants';
import {
  saveUserEmailToStorage,
  setIsUserAuthenticated
} from 'utils/helpers/authHelpers';

import { useUserInfo } from './api';
import { useUpdateUserInfo } from './api/useUpdateUserInfo';
import { useSnackbar } from './useSnackbar';

export const useGoogleAuth = (
  setAuthenticated: Dispatch<SetStateAction<boolean>>
) => {
  const snackbar = useSnackbar();
  const location = useLocation();
  const { data: userInfo } = useUserInfo();
  const { mutate: updateUserInfo } = useUpdateUserInfo(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const isAuthPage = location.pathname === Routes.Auth;
    const isIndustryChatPage = location.pathname.includes('/industry/');

    if (isAuthPage || isIndustryChatPage) {
      if (searchParams.has('isError')) {
        searchParams.delete('isError');

        setSearchParams(searchParams);

        snackbar.error.commonError();
      }

      if (searchParams.has('token')) {
        searchParams.delete('token');

        LocalStorage.setItem(SHOW_LOGIN_MODAL, true);
        LocalStorage.setItem(SHOW_UNPAID_SUBSCRIPTION_MODAL, true);

        setIsUserAuthenticated(true);
        setAuthenticated(true);

        setSearchParams(searchParams);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const isPreferredLanguageSet = userInfo?.preferredLanguage;
    const isCurrentUserEmailInStorage = LocalStorage.getItem(
      CURRENT_USER_EMAIL_KEY
    );

    if (userInfo?.firstName && userInfo?.lastName && !isPreferredLanguageSet) {
      const preferredLanguage = LocalStorage.getItem(APP_UI_LANGUAGE) as string;

      if (preferredLanguage) {
        updateUserInfo({ preferredLanguage });
      }
    }

    if (!isCurrentUserEmailInStorage && userInfo?.email) {
      saveUserEmailToStorage(userInfo.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);
};
