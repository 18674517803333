import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutFooter, Loader } from 'components';
import { OneTimePurchasePlanEnum } from 'enums';
import { useOneTimePaymentSecret } from 'hooks/api/useOneTimePaymentSecret';

import styles from './styles.module.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '');
export const OneTimePaymentCheckout: FC = () => {
  const [searchParams] = useSearchParams();
  const [clientSecret, setClientSecret] = useState('');

  const coinsCount = searchParams.get('count');

  const onSuccess = (secret: string) => {
    setClientSecret(secret);
  };

  const { mutate: getOneTimePaymentSecret, isPending } =
    useOneTimePaymentSecret(onSuccess);

  useEffect(() => {
    if (coinsCount) {
      getOneTimePaymentSecret({
        count: +coinsCount as OneTimePurchasePlanEnum
      });
    }
  }, [coinsCount, getOneTimePaymentSecret]);

  const options = { clientSecret };

  return (
    <>
      <div className={styles.container}>
        {isPending && <Loader loaderClassName={styles.loader} />}
        {clientSecret && (
          <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
      <CheckoutFooter />
    </>
  );
};
