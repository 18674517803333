import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { CommonTooltip, WidgetEmptyState } from 'components';
import { ChatStatement } from 'components/Chat/ChatStatement';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { InsightsType, MessageTypes } from 'enums';

import { ZoomableTreemap } from './ZoomableTreemap';

import styles from './styles.module.scss';

interface Props {
  summary?: string;
  title?: string;
  className?: string;
  data?: DashboardParams;
  widgetType: InsightsType;
}

const WARNING_COLORS = {
  root: '#e74e00',
  start: '#ef6a00',
  end: '#fff3df'
};

const SUCCESS_COLORS = {
  root: '#007727',
  start: '#00983d',
  end: '#e1faec'
};

export const TreemapThemeColors = Object.freeze({
  [InsightsType.Treemap]: SUCCESS_COLORS,
  [InsightsType.HierarchyProducts]: WARNING_COLORS
});
export const ZoomableTreemapChart: FC<Props> = ({
  widgetType,
  data,
  title,
  summary,
  className
}) => {
  const { t } = useTranslation();

  const chartData = data?.data?.[0];
  // @ts-expect-error type error
  const chartColors = TreemapThemeColors[widgetType];

  return (
    <div className={cn(styles.container, className)}>
      <div className={styles.heading}>
        <CommonTooltip
          className={cn(styles.title, 'overflowed-text-multiline')}
          title={title || t('Page.Dashboard.Treemap.Title')}
        />
      </div>
      {chartData ? (
        <div className={styles.treemap}>
          <ZoomableTreemap data={chartData} chartColors={chartColors} />
        </div>
      ) : (
        <WidgetEmptyState className={styles['empty-state']} />
      )}
      {summary && (
        <div className={styles.summary}>
          <ChatStatement
            showAvatar={false}
            maxLettersAmount={1200}
            data={{ type: MessageTypes.answer, statement: summary }}
          />
        </div>
      )}
    </div>
  );
};
