import { ApiError } from 'entities/ApiError.entity';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { FeedbackTypes, MapTypes } from 'enums';
import {
  DashboardApi,
  NestedDashboardPayload
} from 'services/API/Dashboard/DashboardApi';

export const fetchNestedDashboards = async (
  dashboardId: string
): Promise<DashboardData[]> => {
  try {
    const response = await DashboardApi.fetchNestedDashboards(dashboardId);

    return response.data.map(DashboardData.deserialize).reverse();
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchDashboardById = async (
  dashboardId: string
): Promise<DashboardData> => {
  try {
    const response = await DashboardApi.fetchDashboardById(dashboardId);

    return DashboardData.deserialize(response.data);
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const rateDashboardWidget = async ({
  text,
  dashboardId,
  widgetId,
  feedbackType
}: {
  text?: string;
  dashboardId: string;
  feedbackType: FeedbackTypes;
  widgetId: DashboardComponent['id'];
}): Promise<void> => {
  try {
    await DashboardApi.rateDashboardWidget({
      text,
      widgetId,
      dashboardId,
      feedbackType
    });
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const createNestedDashboard = async ({
  dashboardId,
  data
}: {
  dashboardId: string;
  data: NestedDashboardPayload;
}): Promise<{ dashboardId: string }> => {
  try {
    const response = await DashboardApi.createNestedDashboard(
      dashboardId,
      data
    );

    return response.data;
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateWidgetFilter = async ({
  dashboardId,
  widgetId,
  filters,
  mapType
}: {
  dashboardId: string;
  widgetId: DashboardComponent['id'];
  filters: string[];
  mapType?: MapTypes;
}): Promise<void> => {
  try {
    await DashboardApi.updateWidgetFilter({
      dashboardId,
      widgetId,
      filters,
      mapType
    });
  } catch (e) {
    // @ts-expect-error for now
    throw ApiError.deserializeFromCatch(e);
  }
};
