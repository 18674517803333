import { FC, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  SigninForm,
  SigninFormData,
  SignupForm,
  SignupFormData
} from 'components';
import {
  ForgotPasswordForm,
  ForgotPasswordFormData
} from 'components/ForgotPasswordForm/ForgotPasswordForm';
import { GoogleButton } from 'components/GoogleButton';
import { useAuthContext } from 'hooks';
import { useRestorePassword } from 'hooks/api/useRestorePassword';
import { LocalStorage } from 'services/LocalStorage';
import { SessionStorage } from 'services/SessionStorage';
import {
  APP_UI_LANGUAGE,
  DEFAULT_PREFERRED_LANGUAGE,
  SAVED_USER_QUERY
} from 'utils/constants';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

enum AuthViews {
  Signin = 'Signin',
  Signup = 'Signup',
  ForgotPassword = 'ForgotPassword'
}

export const AuthModal: FC<Props> = ({ isOpen, onClose }) => {
  const { signIn, signUp, isLoading } = useAuthContext();
  const { mutate: handleRestorePassword, isPending } = useRestorePassword();
  const { t } = useTranslation();
  const [activeView, setActiveView] = useState<AuthViews>(AuthViews.Signin);
  const savedUserQuery =
    SessionStorage.getItem<string>(SAVED_USER_QUERY) || undefined;

  const signinMethods = useForm<SigninFormData>({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const signupMethods = useForm<SignupFormData>({
    defaultValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: ''
    }
  });

  const forgotPasswordMethods = useForm<ForgotPasswordFormData>({
    defaultValues: {
      email: ''
    }
  });

  const onSignupSubmit = useCallback(
    ({ email, password, firstName, lastName }: SignupFormData) => {
      const trimedEmail = email.trim();
      const trimedFirstName = firstName.trim();
      const trimedLastName = lastName.trim();
      const preferredLanguage: string =
        LocalStorage.getItem(APP_UI_LANGUAGE) || DEFAULT_PREFERRED_LANGUAGE;

      signUp({
        userData: {
          email: trimedEmail,
          password,
          firstName: trimedFirstName,
          lastName: trimedLastName,
          preferredLanguage
        },
        question: savedUserQuery,
        appRedirectPath: window.location.pathname,
        shouldRedirect: false
      });
    },
    [signUp, savedUserQuery]
  );

  const onSigninSubmit = useCallback(
    ({ email, password }: SigninFormData) => {
      const trimedEmail = email.trim();

      signIn({
        userData: {
          email: trimedEmail,
          password
        },
        question: savedUserQuery,
        appRedirectPath: window.location.pathname
      });
    },
    [signIn, savedUserQuery]
  );

  const onForgotPasswordSubmit = useCallback(
    ({ email }: ForgotPasswordFormData) => {
      const trimedEmail = email.trim();
      handleRestorePassword({
        email: trimedEmail,
        question: savedUserQuery,
        appRedirectPath: window.location.pathname
      });
    },
    [handleRestorePassword, savedUserQuery]
  );

  const handleClose = () => onClose();

  const handleToggleView = () => {
    switch (activeView) {
      case AuthViews.Signin:
        setActiveView(AuthViews.Signup);
        break;
      case AuthViews.Signup:
        setActiveView(AuthViews.Signin);
        break;
      case AuthViews.ForgotPassword:
        setActiveView(AuthViews.Signin);
        break;
      default:
        setActiveView(AuthViews.Signup);
    }
  };

  const renderActiveForm = () => {
    switch (activeView) {
      case AuthViews.ForgotPassword:
        return (
          <FormProvider {...forgotPasswordMethods}>
            <ForgotPasswordForm
              onSubmit={onForgotPasswordSubmit}
              isLoading={isPending}
            />
          </FormProvider>
        );

      case AuthViews.Signup:
        return (
          <FormProvider {...signupMethods}>
            <SignupForm
              onSubmit={onSignupSubmit}
              isLoading={isLoading}
              submitLabel={t('Page.Auth.Signup.Submit')}
            />
          </FormProvider>
        );

      case AuthViews.Signin:
      default:
        return (
          <FormProvider {...signinMethods}>
            <SigninForm
              onSubmit={onSigninSubmit}
              isLoading={isLoading}
              submitLabel={t('Page.Auth.Signin.Submit')}
            >
              <span
                onClick={() => setActiveView(AuthViews.ForgotPassword)}
                className={styles['forgot-password']}
              >
                {t('Common.ForgotPassword')}?
              </span>
            </SigninForm>
          </FormProvider>
        );
    }
  };

  const renderSwitchText = () => {
    switch (activeView) {
      case AuthViews.ForgotPassword:
        return t(`Page.Auth.ForgotPassword.ReturnToLogin`);
      case AuthViews.Signup:
        return t(`Common.Signin`);
      case AuthViews.Signin:
      default:
        return t(`Common.Signup`);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <h2 className={styles.title}>{t(`Page.Auth.${activeView}.Title`)}</h2>

      <span className={styles.subtitle}>
        {t(`Page.Auth.${activeView}.Subtitle`)}
      </span>

      {renderActiveForm()}

      <GoogleButton
        hasDivider
        question={savedUserQuery}
        redirectUrl={window.location.href}
      />

      <span className={styles['view-note']}>
        {t(`Page.Auth.${activeView}.Note`)}

        <span onClick={handleToggleView} className={styles['view-switch']}>
          {renderSwitchText()}
        </span>
      </span>
    </Modal>
  );
};
