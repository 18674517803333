import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import cn from 'classnames';
import { EmptyState } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { queryKeys } from 'enums';
import { handleAnimationSwitch } from 'utils/helpers/animationHelper';

import { DashboardHeader } from './DashboardHeader';
import { getDashboardWidget, mapWithClassData } from './dashboardHelpers';

import styles from './styles.module.scss';

export interface DashboardWidgets {
  firstColumn: DashboardComponentExtended[];
  secondColumn: DashboardComponentExtended[];
}

export type DashboardComponentExtended = DashboardComponent & {
  className?: string;
  iconClassName?: string;
};

interface Props {
  isPending?: boolean;
}

export const DashboardV1: FC<Props> = ({ isPending = false }) => {
  const { dashboardId } = useParams();
  const [isDashboardPaid, setIsDashboardPaid] = useState(false);

  const dashboardRef = useRef<HTMLDivElement>(null);

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId!)
  });

  const mappedWidgets = useMemo(
    () => mapWithClassData(dashboardData?.widgets || []),
    [dashboardData?.widgets]
  );

  useEffect(() => {
    setIsDashboardPaid(!!dashboardData?.isPaid);
  }, [dashboardData, dashboardData?.isPaid]);

  useEffect(() => {
    if (dashboardData) handleAnimationSwitch(true);
  }, [dashboardData]);

  const getCard = (widget: DashboardComponentExtended) =>
    getDashboardWidget({
      widget,
      isPaid: isDashboardPaid
    });

  return (
    <div ref={dashboardRef} className={cn(styles['dashboard-wrapper'])}>
      {dashboardData?.title && (
        <DashboardHeader
          title={dashboardData.title}
          createdAt={dashboardData.createdAt}
        />
      )}

      <div className={styles.dashboard}>
        <div className={styles['main-container']}>
          {mappedWidgets?.map((widget) => getCard(widget))}
        </div>

        {!mappedWidgets?.length && !isPending && (
          <EmptyState className={styles['empty-state']} />
        )}
      </div>
    </div>
  );
};
