import { FC, ReactNode, useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useSignIn } from 'hooks/api/useSignIn';
import { useSignUp } from 'hooks/api/useSignUp';
import { AuthContextProvider } from 'hooks/useAuthContext';
import { useGoogleAuth } from 'hooks/useGoogleAuth';
import mixpanel, { MixpanelEvents } from 'mixpanel';
import { LocalStorage } from 'services/LocalStorage';
import {
  APP_UI_LANGUAGE,
  CURRENT_USER_EMAIL_KEY,
  DEFAULT_PREFERRED_LANGUAGE,
  USER_AUTHENTICATED
} from 'utils/constants';
import { clearStorage } from 'utils/helpers/authHelpers';

interface Props {
  children: ReactNode;
}

export const AuthProvider: FC<Props> = ({ children }) => {
  const queryClient = useQueryClient();
  const email = LocalStorage.getItem(CURRENT_USER_EMAIL_KEY);
  const isUserAuthenticated = LocalStorage.getItem(USER_AUTHENTICATED);
  const [currentEmail, setCurrentEmail] = useState(email as string | undefined);
  const [isAuthenticated, setAuthenticated] = useState<boolean>(
    !!isUserAuthenticated
  );

  useGoogleAuth(setAuthenticated);

  const handleReset = useCallback(() => {
    const currentPrefferedLanguage: string =
      LocalStorage.getItem(APP_UI_LANGUAGE) || DEFAULT_PREFERRED_LANGUAGE;
    clearStorage();
    queryClient.removeQueries();
    setAuthenticated(false);
    mixpanel?.track(MixpanelEvents.Logout);
    mixpanel?.reset();

    LocalStorage.setItem(APP_UI_LANGUAGE, currentPrefferedLanguage);
  }, [queryClient]);

  const {
    error: signInError,
    isPending: isSignInLoading,
    mutate: signInMutation
  } = useSignIn(handleReset, setCurrentEmail, setAuthenticated);

  const { isPending: isSignUpLoading, mutate: signUpMutation } =
    useSignUp(handleReset);

  return (
    <AuthContextProvider
      value={{
        isAuthenticated,
        currentEmail,
        isLoading: isSignInLoading || isSignUpLoading,
        logOut: handleReset,
        error: signInError?.errorName,
        signUp: signUpMutation,
        signIn: (payload) => signInMutation(payload)
      }}
    >
      {children}
    </AuthContextProvider>
  );
};
