/* eslint-disable no-restricted-syntax */
import { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { sendMessage } from 'actions/chatsActions';
import { fetchCoins } from 'actions/commonActions';
import { ApiError } from 'entities/ApiError.entity';
import { Message } from 'entities/Message.entity';
import { MessageTypes } from 'enums/MessageTypes.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useAnswerFromStream } from 'hooks/api/useAnswerFromStream';
// import { useFollowupQuestions } from 'hooks/api/useFollowupQuestions';
import { queryClient } from 'index';

export const useCreateMessage = ({
  abortController,
  setSearchValue,
  setIsLoadingAnimation
}: {
  abortController: MutableRefObject<AbortController | null>;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setIsLoadingAnimation?: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  // TODO: uncomment it or remove as soon as there will be a decision about follow up questions
  // const {
  //   mutate: getFollowUpQuestionsMutation,
  //   isPending: isFollowUpQuestionsSending
  // } = useFollowupQuestions(abortController);
  const { setAnswerStatementFromStream } = useAnswerFromStream();

  const { mutate, isPending: isMessageSending } = useMutation<
    {
      id: string;
      messageId: string;
      statement: string;
      dashboardId: string;
    } | void,
    ApiError,
    { message: string; currentChatId: string }
  >({
    mutationFn: ({ message, currentChatId }) => {
      if (setIsLoadingAnimation) setIsLoadingAnimation(true);

      const questionMessage: Message = {
        statement: message,
        type: MessageTypes.question
      };

      queryClient.setQueryData(
        queryKeys.chatMessages(currentChatId),
        (previousMessages: Message[]) => [
          questionMessage,
          ...(previousMessages || [])
        ]
      );

      if (abortController?.current) {
        abortController?.current?.abort();
      }

      // eslint-disable-next-line no-param-reassign
      abortController.current = new AbortController();

      return sendMessage(
        currentChatId!,
        message,
        abortController,
        setAnswerStatementFromStream(currentChatId)
      );
    },
    async onSuccess(successData, { currentChatId }) {
      if (!successData) return;

      await queryClient.invalidateQueries({ queryKey: queryKeys.userCoins });
      await queryClient.fetchQuery({
        queryKey: queryKeys.userCoins,
        queryFn: fetchCoins
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.filteredChats({ includeArchived: false })
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.filteredChats({ includeArchived: true })
      });
      queryClient.invalidateQueries({
        queryKey: queryKeys.nestedDashboards(successData.dashboardId || '')
      });

      queryClient.setQueryData(
        queryKeys.chatMessages(currentChatId || ''),
        (previousMessages: Message[]) =>
          (previousMessages || []).map((previousMessage) => {
            if (previousMessage.id === successData.id) {
              return {
                ...previousMessage,
                ...successData,
                isNotFull: false
              };
            }

            return previousMessage;
          })
      );

      // HINT: Remove false in condition to enable follow up questions or remove this logic
      // const shouldFetchFollowupQuestions =
      //   successData.dashboardId && successData.messageId && false;
      //
      // if (shouldFetchFollowupQuestions) {
      //   getFollowUpQuestionsMutation({
      //     currentChatId,
      //     messageId: successData?.messageId
      //   });
      // }
    },
    onError(error, { currentChatId }) {
      const setMessageData = setAnswerStatementFromStream(currentChatId);
      const id = `error-${Math.random()}`;

      // HINT: Let it be for future needs
      // if (error?.message === 'Free quota limit exceeded') {
      //   const splitedStatement = t('Page.Chat.ErrorQuotaExceeded').split(' ');
      //   const progressiveStatement: string[] = [];
      //   const delayInMs = 30;

      //   splitedStatement.forEach((statement, index) => {
      //     setTimeout(() => {
      //       progressiveStatement.push(statement);
      //       setMessageData({
      //         id,
      //         messageId: id,
      //         statement: progressiveStatement.join(' '),
      //         type: MessageTypes.answer,
      //         hasUpgradeButton: true
      //       });
      //     }, index * delayInMs);
      //   });
      // }

      if (error?.message === 'User is inactive') {
        setMessageData({
          id,
          messageId: id,
          statement: t('Page.Chat.DeactivatedAccountErrorDefaultAnswer'),
          type: MessageTypes.answer
        });
      } else {
        setMessageData({
          id,
          messageId: id,
          statement: t('Page.Chat.ErrorDefaultAnswer'),
          type: MessageTypes.answer
        });
      }
    },
    onSettled() {
      setSearchValue('');
      if (setIsLoadingAnimation) setIsLoadingAnimation(false);
    }
  });

  return {
    mutate,
    // isPending: isMessageSending || isFollowUpQuestionsSending,
    isPending: isMessageSending,
    isMessageSending
  };
};
