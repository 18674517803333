import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { Modal, SubTitle } from 'components';

import styles from './styles.module.scss';

interface Props {
  isOpen: boolean;
  industry: string;
  onClose: () => void;
  onConfirm: () => void;
}

export const DashboardFilterModal: FC<Props> = ({
  isOpen = false,
  industry,
  onClose,
  onConfirm
}) => {
  const { t } = useTranslation();

  const handleCancel = () => onClose();

  const handleConfirm = () => onConfirm();

  return (
    <Modal
      title={t('Page.Dashboard.DashboardFilterModal.Title', { industry })}
      titleClassName={styles.title}
      className={styles.container}
      isOpen={isOpen}
    >
      <SubTitle className={styles.subtitle}>
        {t('Page.Dashboard.DashboardFilterModal.Subtitle', { industry })}
      </SubTitle>

      <div className={styles.buttons}>
        <Button
          size="medium"
          color="secondary"
          variant="outlined"
          className={styles.button}
          onClick={handleCancel}
        >
          {t('Page.Dashboard.DashboardFilterModal.Cancel')}
        </Button>
        <Button
          size="medium"
          variant="contained"
          color="primary"
          className={styles.button}
          onClick={handleConfirm}
        >
          {t('Page.Dashboard.DashboardFilterModal.Confirm')}
        </Button>
      </div>
    </Modal>
  );
};
