import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { fetchCoins } from 'actions/commonActions';
import { Footer } from 'components';
import { DashboardData } from 'entities/Dashboard.entity';
import { DashboardComponent } from 'entities/DashboardComponent.entity';
import { queryKeys, Routes } from 'enums';
import { useUserInfo } from 'hooks/api';
import { useFetchDashboardData } from 'hooks/api/useFetchDashboardData';
import { queryClient } from 'index';

import { DashboardV1 } from '../DashboardV1';
import { DashboardV2 } from '../DashboardV2';

export type DashboardComponentExtended = DashboardComponent & {
  className?: string;
  iconClassName?: string;
};

export const Dashboard: FC = () => {
  const navigate = useNavigate();
  const { dashboardId } = useParams();

  if (!dashboardId) navigate(Routes.NewChat);

  const { data: userInfo } = useUserInfo();

  const {
    isError,
    isPending,
    isSuccess,
    mutate: fetchDashboardData
  } = useFetchDashboardData();

  const { data: dashboardData } = useQuery<DashboardData>({
    queryKey: queryKeys.dashboardDetails(dashboardId!)
  });

  useEffect(() => {
    if (dashboardId) {
      fetchDashboardData({ dashboardId });
    }
  }, [fetchDashboardData, dashboardId]);

  useEffect(() => {
    if (isError) {
      navigate(Routes.NewChat);
    }
  }, [isError, navigate]);

  useEffect(() => {
    if (isSuccess && userInfo?.isFreeUser) {
      queryClient.invalidateQueries({ queryKey: queryKeys.userCoins });
      queryClient.fetchQuery({
        queryKey: queryKeys.userCoins,
        queryFn: fetchCoins
      });
    }
  }, [isSuccess, userInfo?.isFreeUser]);

  return (
    <>
      {dashboardData?.revision === 2 ? (
        <DashboardV2 isPending={isPending} />
      ) : (
        <DashboardV1 isPending={isPending} />
      )}
      <Footer />
    </>
  );
};
