import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import cn from 'classnames';
import { Modal, SubTitle } from 'components';
import { addDays, format } from 'date-fns';
import { Routes } from 'enums';
import { useUnpaidSubscription } from 'hooks';
import { LocalStorage } from 'services/LocalStorage';
import {
  DAYS_BEFORE_AUTO_CANCELLATION,
  SHOW_UNPAID_SUBSCRIPTION_MODAL
} from 'utils/constants';
import { ProfileTabs } from 'views/Profile/Profile';

import styles from './styles.module.scss';

export const UnpaidSubscriptionModal = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { unpaidSubscription } = useUnpaidSubscription();

  const showUnpaidSubscriptionModal = LocalStorage.getItem(
    SHOW_UNPAID_SUBSCRIPTION_MODAL
  );

  const removeModalStateFromLS = () =>
    LocalStorage.removeItem(SHOW_UNPAID_SUBSCRIPTION_MODAL);

  const handleClose = () => {
    setIsOpen(false);
    removeModalStateFromLS();
  };

  const onClick = () => {
    handleClose();
    navigate(Routes.Profile.replace(':tab?', ProfileTabs.Billing));
  };

  useEffect(() => {
    if (unpaidSubscription) {
      if (showUnpaidSubscriptionModal) {
        setIsOpen(true);
      } else {
        handleClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unpaidSubscription, showUnpaidSubscriptionModal]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      titleClassName={styles.title}
      title={t('Common.UnpaidSubscriptionModal.Title')}
    >
      {unpaidSubscription?.createdAt && (
        <SubTitle className={styles.subtitle}>
          {t('Common.UnpaidSubscriptionModal.Subtitle', {
            renewDate: format(unpaidSubscription?.createdAt, 'PP'),
            finalRenewDate: format(
              addDays(
                unpaidSubscription?.createdAt,
                DAYS_BEFORE_AUTO_CANCELLATION
              ),
              'PP'
            )
          })}
        </SubTitle>
      )}

      <div className={styles.buttons}>
        <Button
          size="medium"
          type="submit"
          color="primary"
          variant="contained"
          className={cn(styles.button, 'brilliance')}
          onClick={onClick}
        >
          {t('Common.UnpaidSubscriptionModal.GoToBilling')}
        </Button>
      </div>
    </Modal>
  );
};
