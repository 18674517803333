/* eslint-disable react/no-unknown-property */
import { FC, memo, useMemo } from 'react';
import { OrbitControls } from '@react-three/drei';
import { Canvas, RootState } from '@react-three/fiber';
import { Bloom, EffectComposer, Noise } from '@react-three/postprocessing';
import cn from 'classnames';
import { useIsDashboardAccessible } from 'hooks';
import { Fog } from 'three';

import { Particle } from '../Particle';
import { ParticleSelectData } from '../ParticleCloud';

import styles from './styles.module.scss';

enum ParticleColors {
  Green = '#7DEAB2',
  Orange = '#FFCB7D',
  Gray = '#8F8F8F'
}

interface Props {
  filters: {
    first: ParticleSelectData;
    second: ParticleSelectData;
  };
}

const PARTICLES_COUNT = 2500;

export const ParticleView: FC<Props> = memo(({ filters }) => {
  const { first, second } = filters;
  const isDashboardAccessible = useIsDashboardAccessible();

  const firstGroupCount = Number(first.value) * PARTICLES_COUNT;
  const secondGroupCount =
    firstGroupCount + Number(second.value) * PARTICLES_COUNT;

  const getParticleColor = (index: number) => {
    if (index <= firstGroupCount) {
      return ParticleColors.Green;
    }

    if (index > firstGroupCount && index <= secondGroupCount) {
      return ParticleColors.Orange;
    }

    return ParticleColors.Gray;
  };

  const getParticlePosition = (
    index: number
  ): [x: number, y: number, z: number] => {
    let x = Math.random() - 0.5;
    let y = Math.random() - 0.5;
    let z = Math.random() - 0.5;

    if (index <= firstGroupCount) {
      x -= 0.5;

      if (x > -0.2) {
        x += Math.random() * 0.5;
      }
    }

    if (index > firstGroupCount && index <= secondGroupCount) {
      x += 0.5;

      if (x < 0.2) {
        x -= Math.random() * 0.5;
      }
    }

    const mag = Math.sqrt(x * x + y * y + z * z) * 0.9;

    x /= mag;
    y /= mag;
    z /= mag;

    x *= Math.random() * 3;
    y *= Math.random() * 3;
    z *= Math.random() * 3;

    return [x, y, z];
  };

  const particles = useMemo(() => {
    const mappedParticles = [];

    for (let index = 0; index < PARTICLES_COUNT; index += 1) {
      mappedParticles.push(
        <Particle
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          color={getParticleColor(index)}
          position={getParticlePosition(index)}
        />
      );
    }

    return mappedParticles;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first, second]);

  const handleCanvasCreate = (state: RootState) => {
    // eslint-disable-next-line no-param-reassign
    state.scene.fog = new Fog('#444657', 1, 10);
  };

  return (
    <>
      <div
        className={cn(styles.canvas, !isDashboardAccessible && styles.hidden)}
      >
        <Canvas
          gl={{ preserveDrawingBuffer: true }}
          onCreated={handleCanvasCreate}
        >
          <ambientLight intensity={0.5} />
          <spotLight
            position={[-3, -7, -10]}
            angle={0.22}
            penumbra={1}
            decay={0}
            intensity={Math.PI * 6}
          />

          <spotLight
            position={[8, 2, 10]}
            angle={0.22}
            penumbra={1}
            decay={0}
            intensity={Math.PI * 6}
          />

          {particles}
          <EffectComposer>
            <Bloom
              intensity={0.16}
              luminanceThreshold={0.5}
              luminanceSmoothing={0.1}
            />
            <Noise opacity={0.05} />
          </EffectComposer>
          <OrbitControls autoRotate autoRotateSpeed={0.7} enableZoom={false} />
        </Canvas>
      </div>
    </>
  );
});
