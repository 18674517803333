import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { CommonTooltip, WidgetEmptyState } from 'components';
import { DashboardParams } from 'entities/DashboardParams.entity';
import { useIsDashboardAccessible } from 'hooks';
import millify from 'millify';
import {
  Cell,
  Pie,
  PieChart as PieChartComponent,
  ResponsiveContainer,
  Sector,
  Tooltip
} from 'recharts';
// eslint-disable-next-line import/no-unresolved
import { PieSectorDataItem } from 'recharts/types/polar/Pie';

import { PieChartTooltip } from './PieChartTooltip';

import styles from './styles.module.scss';

export const COLORS = [
  '#f57a00',
  '#ff9600',
  '#00be56',
  '#00d878',
  '#ffb648',
  '#ffcb7d',
  '#12e192',
  '#7deab2',
  '#ffe0b0',
  '#b5f2d0'
];

const renderActiveShape =
  (activeIndex: number | undefined) => (props: PieSectorDataItem) => {
    const {
      cx = 0,
      cy = 0,
      innerRadius = 0,
      outerRadius = 0,
      startAngle,
      endAngle,
      fill
    } = props;

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          cornerRadius={4}
          endAngle={endAngle}
          fill={fill}
        />
        {activeIndex !== undefined && (
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            cornerRadius={2}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={fill}
          />
        )}
      </g>
    );
  };

interface Props {
  data: DashboardParams;
}

const chartMeasurements = {
  full: {
    textY: 10,
    startAngle: -360,
    cy: '50%',
    innerRadius: 65,
    outerRadius: 95
  },
  half: {
    textY: -10,
    startAngle: 180,
    cy: '70%',
    innerRadius: 75,
    outerRadius: 105
  }
};

export const PieChart: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const isDashboardAccessible = useIsDashboardAccessible();
  const [activeIndex, setActiveIndex] = useState<number>();

  const mappedData = (data?.labels || []).map((label, index) => ({
    name: label,
    value: data.values?.[index]
  }));

  const totalAmount = useMemo(
    () =>
      data?.values?.reduce((a, b) => {
        if (typeof a === 'number' && typeof b === 'number') {
          return a + b;
        }
        return 0;
      }, 0),
    [data?.values]
  );

  const showHalfPieChart = (data?.values?.length || 0) < 4;
  const { cy, textY, startAngle, outerRadius, innerRadius } = showHalfPieChart
    ? chartMeasurements.half
    : chartMeasurements.full;

  const totalAmountFormatted = useMemo(() => {
    const total = typeof totalAmount === 'number' ? totalAmount : 0;

    if (total.toString().length > 4) {
      return millify(total, {
        precision: 1,
        lowercase: true
      });
    }
    return total;
  }, [totalAmount]);

  const onMouseEnter = (_data: unknown, index: number) => {
    setActiveIndex(index);
  };

  const onMouseLeave = () => {
    setActiveIndex(undefined);
  };

  const handleRemoveTooltip = useCallback((event: MouseEvent) => {
    const isPieChartSector = (
      event?.target as SVGPathElement
    )?.classList?.contains('recharts-sector');

    if (!isPieChartSector) {
      setActiveIndex(undefined);
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('mouseover', handleRemoveTooltip);

    return () => {
      document.body.removeEventListener('mouseover', handleRemoveTooltip);
    };
  }, [handleRemoveTooltip]);

  return (
    <div className={styles.container}>
      <div>
        <CommonTooltip
          className={cn(styles.title, 'overflowed-text-multiline')}
          title={data?.title || t('Page.Dashboard.PieChart.Title')}
        />
      </div>
      {mappedData?.length ? (
        <div className={styles['chart-container']}>
          <div className={styles['pie-chart-container']}>
            <ResponsiveContainer>
              <PieChartComponent className={styles['pie-chart']}>
                <Tooltip
                  wrapperStyle={{
                    display: activeIndex !== undefined ? 'block' : 'none'
                  }}
                  cursor={false}
                  position={{ y: -50 }}
                  content={<PieChartTooltip />}
                />
                <Pie
                  // eslint-disable-next-line react/no-unstable-nested-components
                  label={(props: { cx: number; cy: number }) => {
                    return (
                      <g>
                        <text
                          x={props.cx}
                          y={props.cy + textY}
                          fill="#161616"
                          textAnchor="middle"
                          className={styles['total-value']}
                        >
                          {`${totalAmountFormatted}%`}
                        </text>
                      </g>
                    );
                  }}
                  startAngle={startAngle}
                  endAngle={0}
                  radius={4}
                  activeIndex={activeIndex}
                  cx="50%"
                  cy={cy}
                  innerRadius={innerRadius}
                  outerRadius={outerRadius}
                  fill="#8884d8"
                  dataKey="value"
                  paddingAngle={3}
                  data={mappedData}
                  cornerRadius={4}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  className={styles.pie}
                  activeShape={renderActiveShape(activeIndex)}
                >
                  {(mappedData || []).map((entry, index) => (
                    <Cell
                      strokeWidth={0}
                      key={`cell-${entry.name}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChartComponent>
            </ResponsiveContainer>
          </div>
          <div
            className={cn(styles.rows, !isDashboardAccessible && styles.hidden)}
          >
            <div className={styles.row}>
              <span className={styles['rows-title']}>
                {t('Page.Dashboard.PieChart.Name')}
              </span>
              <span className={styles['rows-title']}>
                {t('Page.Dashboard.PieChart.Value')}
              </span>
            </div>
            {mappedData.map((row, index) => (
              <div key={row.name} className={styles.row}>
                <div className={cn(styles['label-container'])}>
                  <span
                    className={styles.color}
                    style={{
                      backgroundColor: COLORS[index % COLORS.length]
                    }}
                  />
                  <span className={styles.label}>{row.name}</span>
                </div>
                <span
                  className={cn(
                    styles.value,
                    !isDashboardAccessible && index && styles.hidden
                  )}
                >{`${row.value}%`}</span>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <WidgetEmptyState />
      )}
    </div>
  );
};
